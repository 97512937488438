import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessuiv2/react'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { json } from '@remix-run/node'
import { useFetcher } from '@remix-run/react'
import classnames from 'classnames'
import { useEffect, useMemo } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { inputClassName as defaultClassName } from '~/components/design-system/form/input'
import AnchorIcon from '~/icons/anchor-icon'
import TerminalIcon from '~/icons/terminal-icon'
import { getDefaultHeaders, RoutescannerApiFetchClient } from '~/services/routescanner-api.server'
import { SelectLocationParamsSchema, SelectLocationResponseSchema } from './schema'
import type { SelectLocation } from './schema'
import type { LoaderFunctionArgs } from '@remix-run/node'
import type { ComponentPropsWithoutRef, ComponentType } from 'react'
import type { SitemapFunction } from 'remix-sitemap'

export const sitemap: SitemapFunction = () => ({
  exclude: true
})

type Props = {
  name: string

  value?: SelectLocation | null
  defaultValue?: SelectLocation | null
  onChange?: (value: SelectLocation | null) => void
  placeholder?: string

  inputClassName?: string
  containerClassName?: string

  leadingIcon?: ComponentType<any>
  leadingIconProps?: ComponentPropsWithoutRef<'svg'>

  'data-invalid'?: boolean
}

export async function loader({ request }: LoaderFunctionArgs) {
  const defaultHeaders = await getDefaultHeaders(request)
  const API = new RoutescannerApiFetchClient(defaultHeaders)

  try {
    const url = new URL(request.url)
    const searchParams = new URLSearchParams(url.search)

    const { q } = SelectLocationParamsSchema.parse(searchParams)

    const suggestionsData = await API.fetchSuggestions({ q })
    const suggestions = SelectLocationResponseSchema.parse(suggestionsData)

    return json(suggestions)
  } catch (error) {
    console.error(error)
    return json([])
  }
}

export default function SelectLocation({
  name,
  onChange,

  inputClassName,
  containerClassName,
  'data-invalid': dataInvalid,

  value,
  defaultValue,
  placeholder,

  leadingIcon: LeadingIcon,
  leadingIconProps
}: Props) {
  const fetcher = useFetcher<typeof loader>()
  const [debouncedValue, setValue] = useDebounceValue('', 500)

  useEffect(() => {
    if (!debouncedValue) {
      return
    }

    fetcher.load(`/fullstack-components/select-location?q=${debouncedValue}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const options = useMemo(() => {
    if (debouncedValue === '') {
      return []
    }

    if (fetcher.data) {
      return fetcher.data
    }

    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.state, debouncedValue])

  const showNotFound = options.length === 0

  return (
    <Combobox
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={(suggestion: SelectLocation | null) => onChange && onChange(suggestion)}>
      <div className={classnames(containerClassName)}>
        <div className="relative">
          <ComboboxInput
            onChange={e => setValue(e.target.value)}
            className={classnames(defaultClassName, inputClassName, {
              'pl-9': !!LeadingIcon
            })}
            displayValue={(suggestion: SelectLocation) => suggestion?.name ?? ''}
            placeholder={placeholder || 'City, port or terminal'}
            {...(dataInvalid ? { 'data-invalid': true } : {})}
          />

          {LeadingIcon && (
            <div className="absolute inset-y-0 left-3 flex items-center justify-center">
              <LeadingIcon className="size-5 shrink-0" {...leadingIconProps} aria-hidden="true" />
            </div>
          )}
        </div>

        <ComboboxOptions className="absolute !z-[50] mt-1 max-h-80 w-96 overflow-auto rounded-md border border-brand-medium bg-white text-sm shadow-subtle">
          {showNotFound && <div className="relative cursor-default select-none px-4 py-2 text-brand-dark">No locations found</div>}

          {options.map(option => (
            <LocationSuggesterOption key={option.id} option={option} />
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  )
}

function LocationSuggesterOption({ option }: { option: SelectLocation }) {
  return (
    <ComboboxOption
      value={option}
      className={({ focus }) =>
        classnames('relative select-none px-4 py-2', { 'cursor-pointer bg-brand-lightest': focus, 'cursor-default': !focus })
      }>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-4">
          {locationSuggesterOptionIcon(option.type)}

          <div className="flex flex-col">
            <div className="text-brand-darkest">{option.name}</div>
            <div className="text-xs text-brand-dark">{locationSuggesterOptionSubtitle(option.type)}</div>
          </div>
        </div>

        {'locode' in option && (
          <div className="w-16 shrink-0 self-center rounded border border-brand-primary-accent bg-brand-primary-bg py-1 text-center text-xs font-bold text-brand-darkest">
            {option.locode}
          </div>
        )}
      </div>
    </ComboboxOption>
  )
}

const locationSuggesterOptionIcon = (type: SelectLocation['type']) => {
  switch (type) {
    case 'locode':
      return <AnchorIcon className="size-6 shrink-0" />
    case 'terminal':
      return <TerminalIcon className="size-6 shrink-0" />
    case 'address':
    case 'geo':
      return <MapPinIcon className="size-6 shrink-0" />
  }
}

const locationSuggesterOptionSubtitle = (type: SelectLocation['type']) => {
  switch (type) {
    case 'locode':
      return 'All terminals'
    case 'terminal':
      return 'Terminal'
    case 'address':
      return 'Address'
    case 'geo':
      return 'Coordinates'
  }
}
